import React, { ReactNode } from 'react'
import ErrorBoundary from 'components/ErrorBoundary'
import { AppProvider, ModalProvider } from 'context'
import PageviewTracker from 'libs/PageviewTracker'
import EventSearchPage from 'pages/EventSearchPage'
import PostModal from 'components/PostModal'
import PostList from 'pages/PostList'
import ScheduleItemList from 'pages/ScheduleItemList'
import { Route, Routes } from 'react-router'
import './style.scss'

export default function App (): ReactNode {
  return (
    <ErrorBoundary>
      <AppProvider>
        <ModalProvider>
          <Routes>
            {/* <Route path="/:locator/events/:date" element={} /> */}
            <Route
              path='/:locator/:scheduleItemId/:postId'
              element={<PostModal />}
            />
            <Route path='/:locator/:scheduleItemId/' element={<PostList />} />
            <Route path='/:locator/' element={<ScheduleItemList />} />
            <Route path='*' element={<EventSearchPage />} />
          </Routes>
        </ModalProvider>
      </AppProvider>
      <PageviewTracker />
    </ErrorBoundary>
  )
}
