import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router'
import App from 'components/App'
import PerformanceTracker from 'components/PerformanceTracker'
import * as globals from './globals'

import './styles/styles.scss'
import './styles/variables.css'
import 'fonts/Proxima_Nova.css'
import 'themes'

console.log(`StagePilot VIP Gallery version ${globals.gitRev}`)

const rootDOM = createRoot(document.getElementById('root') as Element)
rootDOM.render(
  <Router>
    <PerformanceTracker />
    <App />
  </Router>
)

const noJS = document.querySelector('.no-js')

if (noJS != null) {
  noJS.className = ''
}
