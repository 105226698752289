import { useContext, useEffect } from 'react'
import { AppContext, IAppContext } from 'context'
import { trackDownload } from 'api'
import pick from 'lodash.pick'
import { useParams } from 'react-router'

const EVENT_ATTRIBUTES = [
  'eventType',
  'eventId',
  'scheduleItemId',
  'occurredAt',
  'body'
]

export default function EngagementTracker (): JSX.Element | null {
  const { event } = useContext<IAppContext>(AppContext)
  const { locator } = useParams()
  useEffect(() => {
    document.addEventListener('engagementEvent', trackEngagementEvent)
    return () => {
      document.removeEventListener('engagementEvent', trackEngagementEvent)
    }
  })

  const trackEngagementEvent = (e: any): void => {
    const { eventType, attributes } = e.detail
    const { eventContext } = buildEventContext(eventType, attributes)

    void trackDownload(locator ?? '', eventContext)
  }

  const buildEventContext = (eventType: string, attributes: any): any => {
    const eventAttributes = {
      eventType,
      ...pick(attributes, EVENT_ATTRIBUTES)
    }

    let body = attributes.body ?? {}
    const bodyKeys = Object.keys(attributes).filter(
      (k) => !EVENT_ATTRIBUTES.includes(k)
    )
    body = Object.assign(pick(attributes, bodyKeys), body)

    const eventContext = { ...eventAttributes, body }

    return { locator: event?.locator, eventContext }
  }
  return null
}

export const trackEngagementEvent = (
  eventType: string,
  attributes: any
): void => {
  document.dispatchEvent(
    new CustomEvent('engagementEvent', { detail: { eventType, attributes } })
  )
}
